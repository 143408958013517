import React,{Component} from 'react';
import  {withRouter} from 'react-router'
import {connect} from 'react-redux'
import * as userregisteration from '../../actions/UserAddition'



class Adduser extends Component{

    constructor(props) {
        super(props);
        this.state={
            userFirstnameerror: false,
            userLastnameerror: false,
            userroleerror: false,
            userEmailiderror: false,
            userPhoneerror: false,
            userPassworderror: false,
            userCpassworderror: false,
            userFirstname:"",
            userLastname:"",
            userrole:"",
            userEmailid:"",
            userPhone:"",
            userPassword:"",
            userCpassword:""


        }

    }
    handleClosepopup=()=>{

        this.props.userpopupclose('no-refresh')
    }
    handleadduser=(e)=>{

        this.setState({
            [e.target.name] : e.target.value
        })

    }
    handleaddusersubmit=(e)=>{

       let isValid=false;

        e.preventDefault();

        console.log(this.state.userFirstname);

        if(this.state.userFirstname==''){
            isValid=true;
            this.setState({userFirstnameerror: true}

            )

        }

        else{
            isValid=false;
            this.setState({userFirstnameerror: false})

        }
        if(this.state.userLastname==""){
            isValid=true;
            this.setState({userLastnameerror: true})
        }
        else{
            isValid=false;
            this.setState({userLastnameerror: false })
        }
        if(this.state.userrole==""){
            isValid=true;
            this.setState({userroleerror: true})
        }
        else{
            isValid=false;
            this.setState({userroleerror: false})
        }
        if(this.state.userEmailid==""){
            isValid=true;
            this.setState({userEmailiderror: true})
        }
        else{
            isValid=false;
            this.setState({userEmailiderror: false })
        }
        if(this.state.userPhone==""){
            isValid=true;
            this.setState({userPhoneerror: true})
        }
        else{
            isValid=false;
            this.setState({
                userPhoneerror: false
            })
        }




        if(this.state.userPassword==""){
            isValid=true;
            this.setState({
                userPassworderror: true
            })
        }

        else{
            isValid=false;
            this.setState({
                userPassworderror: false
            })

        }
        if(this.state.userCpassword==""){
            isValid=true;
            this.setState({
                userCpassworderror: true
            })
        }


        else if (this.state.userPassword !== this.state.userCpassword){
            isValid=true;
            this.setState({
                userCpassworderror: true
            })
        }
        else{
            isValid=false;
            this.setState({
                userCpassworderror: false
            })
        }




        if(isValid===false){
            this.props.adduser(this.state.userFirstname,this.state.userLastname,this.state.userrole,this.state.userEmailid,this.state.userPhone,this.state.userPassword)

            setTimeout(() => {
                this.props.userpopupclose('refresh')
            }, 500);

        }



    }







    render(){

        return(

            <div className="cs-modal-in cs-modal--lg show">
                <div className="modal-content">
                    <div className="cs-modal-head">
                        <h3 className="cs-modal-header-in">Add New User</h3>
                        <span className="modal-out" onClick={this.handleClosepopup}></span>
                    </div>
                    <div className="cs-modal-body">
                        <div className="cs-modal-boy-in">
                            <form name="loginAdminForm" id="userform" >


                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">First Name:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <input type="text" id="userFirstname" name="userFirstname" autoFocus="autofocus"
                                                onChange={this.handleadduser}  className={`modal-login-form-input ${this.state.userFirstnameerror?'errorclsuser':''}`} />
                                    </div>
                                </div>
                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">Last Name:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <input type="text" id="userLastname" name="userLastname"
                                               className={`modal-login-form-input ${this.state.userLastnameerror?'errorclsuser':''}`} onChange={this.handleadduser}/>
                                    </div>
                                </div>
                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">User Role:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <select name="userrole" id="userrole" onChange={this.handleadduser} className={`form-user-select cs-select ${this.state.userroleerror ? 'errorclsuser' : ''}`}>
                                            <option selected="" value="norole"></option>
                                            <option>Admin</option>
                                            <option>Evaluator</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">Email Id:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <input type="text" id="userEmailid" name="userEmailid"
                                               className={`modal-login-form-input ${this.state.userEmailiderror?'errorclsuser':''}`} onChange={this.handleadduser}/>
                                    </div>
                                </div>


                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">Phone Number:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <input type="text" id="userPhone" name="userPhone"
                                               className={`modal-login-form-input ${this.state.userPhoneerror?'errorclsuser':''}`} onChange={this.handleadduser}/>
                                    </div>
                                </div>


                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">Password:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <input type="password" id="userPassword" name="userPassword"
                                               className={`modal-login-form-input ${this.state.userPassworderror?'errorclsuser':''}`} onChange={this.handleadduser} />
                                    </div>
                                </div>


                                <div className="modal-form-row">
                                    <div className="modal-label-block">
                                        <label className="modal-login-form-label">Confirm Password:</label>
                                    </div>
                                    <div className="modal-input-block">
                                        <input type="password" id="userCpassword" name="userCpassword"
                                               className={`modal-login-form-input ${this.state.userCpassworderror?'errorclsuser':''}`} onChange={this.handleadduser} />
                                    </div>
                                </div>


                                <div className="modal-submit-block">
                                    <a  onClick={this.handleaddusersubmit} className="btn primary-btn mod-out">Register</a>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>


        );

    }
}



export default withRouter(connect('', {
    adduser:userregisteration.AddnewUsers
})(Adduser));



