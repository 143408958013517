import React,{Component} from 'react';
import  {withRouter} from 'react-router'
import {connect} from 'react-redux'
import * as userActions from "../../actions/User";



class Deleteuser extends Component{

    constructor(props) {
        super(props);
    }


    handleClosepopup=()=>{

        this.props.userpopupclose('no-refresh')
    }

    handleDeleteUser=(deleteid)=>{

      console.log(deleteid)

        this.props.deleteUser(deleteid)

        setTimeout(() => {
            this.props.userpopupclose('refresh')
        }, 500);


    }


    render(){

        var deleteid = this.props.userdeleteid;

        return(

            <div className="cs-modal-in cs-modal--lg show">
                <div className="modal-content delmodal">
                    <div className="cs-modal-head">
                        <h3 className="cs-modal-header-in">Are you sure you want to Delete the User?</h3>
                        <span className="modal-out" onClick={this.handleClosepopup}></span>
                    </div>
                    <div className="cs-modal-body">
                        <div className="cs-modal-body-in">

                            <div className="btn-all-holder">
                                <a onClick={()=>this.handleDeleteUser(deleteid)} className="btn btn-green">Yes</a>
                                <a  onClick={this.handleClosepopup} className="btn btn-red">No</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        );

    }
}

const mapStateToProps = state => ({



});

export default  withRouter(connect(mapStateToProps, {
    deleteUser:userActions.deleteuser
})(Deleteuser));




