import decode from 'jwt-decode';

export function  loggedIn() {

        // Checks if there is a saved token and it's still valid
        const token = getToken()
        // GEtting token from localstorage
        return !!token && !isTokenExpired(token) // handwaiving here

}


export function logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('eaf_id_token');
}
export  function login(token, userrole, fname, lname, profile) {
    setToken(token, userrole, fname, lname, profile)
    return Promise.resolve(token);
}

export function tokenId() {

    var user=[];
    user['id']= localStorage.getItem("eaf_id_token")
    return user

}

export function userRole() {

    var user=[];
    user['userrole']= localStorage.getItem("eaf_userrole")
    return user

}

export function userName() {

    var user=[];
    user['fname']= localStorage.getItem("eaf_fname")
    user['lname']= localStorage.getItem("eaf_lname")
    return user

}

export function userProfile() {

    var user=[];
    user['profile']= localStorage.getItem("eaf_profile")
    return user

}

function setToken(idToken, userrole, fname, lname, profile) {
    localStorage.setItem('eaf_id_token', idToken)
    localStorage.setItem('eaf_userrole', userrole)
    localStorage.setItem('eaf_fname', fname)
    localStorage.setItem('eaf_lname', lname)
    localStorage.setItem('eaf_profile', profile)
}

function fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
        headers['Authorization'] = 'Bearer ' + getToken()
    }

    return fetch(url, {
        headers,
        ...options
})
.then(_checkStatus)
        .then(response => response.json())
}

function _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}
function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
            return true;
        }
        else
            return false;
    }
    catch (err) {
        return false;
    }
}
function getToken() {


        return localStorage.getItem("eaf_id_token")

}

export function getProfile() {
    /* get Jwt Token Data from local storage */

        /* codeigniter shared login */
        return (getToken())
        //return getToken();

}