import React, {Component} from 'react';
import {connect} from "react-redux";
import * as logintActions from "../../actions/Login"
import {withRouter} from 'react-router';


class Login extends Component {

    constructor(props) {
        super(props);
        this.Login=this.Login.bind(this)
        this.formHandler=this.formHandler.bind(this)
        this.formFocused=this.formFocused.bind(this)
        this.state={
            username:'',
            password:'',
            userError:false,
            passwordError:false,
            userFocused:false,
            passFocused:false
        }

    }

    formHandler(e){

        this.setState({[e.target.name]:e.target.value},()=>{

            console.log(this.state.username);
            console.log(this.state.password);

            if(this.state.username !== ''){
                this.setState({userError:false})
            }

            if(this.state.password !==''){
                this.setState({passwordError:false})
            }


        })

    }

    formFocused(e){
        if(e.target.name === 'username'){
            this.setState({userFocused:true})
        }else if(e.target.name === 'password'){
            this.setState({passFocused:true})
        }

    }




    Login(e) {
        e.preventDefault();
        if(this.state.username === ''){
            this.setState({userError:true})
        }
        else{
            this.setState({userError:false})
        }
        if(this.state.password===''){
            this.setState({passwordError:true})
        }
        else{
            this.setState({passwordError:false})
        }

        if(this.state.username !=='' && this.state.password !==''  ){
            this.props.login(this.state.username,this.state.password)
        }
    }

    render() {
        console.log(this.props)

        console.log(this.props.getLoginError.Validdata);


        return (


                <div className="login-box-panel">
                    <div className="login-box">
                        <div className="login-box__left">
                            <form className="login-form" onSubmit={this.Login}>
                                <h1 className="form-head">E A Fernandez Memorial Award</h1>

                                {this.props.getLoginError.Validdata===false ? (<p className="validdataerrors">Please enter a Valid Username and Password</p>) : ''}


                                <div className="form-items">
                                    <div className="form-items__each">
                                        <div  className={`input-holder ${this.state.userFocused?'focused':''} ${this.state.userError?'errorcls':''}`} >
                                            <input type="text" name="username" className={`labeled-input`}   onChange={this.formHandler} onFocus={this.formFocused} />
                                            <label className="input-label">Username</label>
                                        </div>
                                    </div>

                                    <div className="form-items__each">
                                        <div   className={`input-holder ${this.state.passFocused?'focused':''} ${this.state.passwordError?'errorcls':''}`} >
                                            <input type="password" name="password" className={`labeled-input`}  onChange={this.formHandler} onFocus={this.formFocused}  />
                                            <label className="input-label">Password</label>
                                        </div>
                                    </div>

                                    <div className="form-items__each">
                                        <label className="cs-checkbox">
                                            <input type="checkbox" className="cs-checkbox-in" name="rememberMe" id="remember" />
                                            <span className="cs-placeholder"></span>
                                            <label className="cs-checkbox-label" label-for="remember">Remember Me</label>
                                        </label>
                                    </div>

                                    <div className="form-items__each">
                                            <button className="btn-primary submit-btn">LOGIN NOW</button>
                                    </div>

                                    {/*<div className="form-items__each">*/}
                                    {/*    <p>Haven’t an account? <a href="javascript:void(0);" className="primary-link">Sign Up</a></p>*/}
                                    {/*</div>*/}
                                </div>
                            </form>
                        </div>
                        {/*<div className="login-box__right">
                            <img src="./assets/images/eafernandez.jpg" alt="eafernandez" className="full-image" />
                            <p className="image-over-text">E A Fernandez (1930 - 2009) was a journalist, writer and publisher from Trivandrum, Kerala, India.</p>
                        </div>*/}
                    </div>
                </div>



        );

    }
}



const mapStateToProps = state => ({
    getLoginError:state.LoginfailReducer.getLoginError,


});



export default  withRouter(connect(mapStateToProps, {
    login:logintActions.UserLogin
})(Login));
