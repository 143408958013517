import {combineReducers} from 'redux';

const getLoginError =  (state = {Validdata:[]}, action) => {
    switch (action.type) {
        case "LOGIN_FAILED":
            return {
                ...state,
                Validdata: action.validdataerrors,
            };
            break;

        default:
            return state;
    }
};



export const Errors = combineReducers({
    getLoginError
});

export default Errors;