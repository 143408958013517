import axios from 'axios'
import {API_PATH} from '../constants/index'


export const Stories = {
    listStories,
    readstory,
    readcriteria,
    updatescore,
    scorecheck,
    listShortlistedStories,
    shortlistingstory,
    allscoresCheck,
    readassets,
    listAllEntries,
    nextstory,
    assignmarks

};


function listStories(encid,year){
    return axios.get(API_PATH + 'admin/liststories?&evaluator='+encid+'&year='+year)
}

function nextstory(storyid, encid) {
    return axios.get(API_PATH + 'admin/listnextstory?storyid='+storyid+'&evaluator='+encid)
}

function listAllEntries(encid,year){
    return axios.get(API_PATH + 'admin/listallentries?evaluator='+encid+'&year='+year)
}

function listShortlistedStories(){
    return axios.get(API_PATH + 'admin/ShortlistDetails')
}


function readstory(storyid,encid){
    return axios.get(API_PATH + 'admin/readstory?storyid='+storyid+'&evaluator='+encid)
}

function scorecheck(storyid,encid){
    return axios.get(API_PATH + 'admin/checkscore?storyid='+storyid+'&evaluator='+encid)
}

function allscoresCheck(storyid,encid){
    return axios.get(API_PATH + 'admin/allscore?storyid='+storyid+'&evaluator='+encid)
}

function readcriteria(){
    return axios.get(API_PATH + 'admin/evaluationcriterialisting')
}

function readassets(storyid) {
    return axios.get(API_PATH + 'admin/Allassets?storyid='+storyid)
}



function shortlistingstory(params){
    console.log(params);
    return axios.post(API_PATH + 'admin/shortlistStory', {

        "params":params,
    })
}

function updatescore(storyid,encid,selectedOption,action){
    return axios.post(API_PATH + 'admin/insertscore', {

        "storyid": storyid,
        "evaluator": encid,
        "selectedOption": selectedOption,
        "action" : action

    })
}
function assignmarks(storyid,marks){
    return axios.post(API_PATH + 'admin/storymarks', {

        "storyid": storyid,
        "marks":marks

    })
}


