import React, {Component} from 'react';
import {connect} from "react-redux";
import * as userActions from "../../actions/User"
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'
import Adduser from './AddUserPopup'
import Deleteuser from './UserDeletePopup'

class Displayrows extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserCheckBox=this.handleUserCheckBox.bind(this)

    }

    componentDidMount() {

        document.getElementsByName("checkboxname").checked = false;


    }

    componentDidUpdate(prevProps, nextProps) {

        if (prevProps.displist !== this.props.displist) {
            console.log("remove selection") ;
            var checkboxNodes = document.getElementsByName('users');
            for (var i = 0; i < checkboxNodes.length; i++) {
                checkboxNodes[i].checked = false;
            }
        }
    }

    handleUserCheckBox=(id)=>{
        this.props.deleteid(id);


    }


    render() {
        let  displist = this.props.displist;
        return (
            <tbody>
                    {displist.map((list, index) =>
                        <tr key={`list-users-table-data-`+index}>
                            <td>
                                <label className="cs-checkbox">
                                    <input type="checkbox" name="users" value={list.id}
                                           onClick={() => this.handleUserCheckBox(list.id)} className="cs-checkbox-in"/>
                                    <span className="cs-placeholder"></span>
                                </label>
                                <label className="sl-no">{index + 1}</label>
                                <label className="inline-text">{list.fname} {list.lname}</label>
                            </td>
                            <td>{list.user_role}</td>
                            <td>{list.phone}</td>
                            <td>{list.emailid}</td>
                            <td className="date-text">{list.created_date}</td>
                        </tr>
                    )}
            </tbody>

        );
    }

}



class Listuser extends Component {

    constructor(props) {
        super(props);
        this.FilterList=this.FilterList.bind(this)

        this.state={
            filter:'',
            adduserop: false,
            userpopup:false,
            userdeleteid:'',
            refresh: 'no-refresh'

        }

    }

    componentDidMount() {

        this.props.user();

    }




    deleteid=(id)=>{
        this.setState({
            userdeleteid:id,
            userpopup:false
        })

    }


    handlePopup=()=>{

        this.setState({

            adduserop: true
        })


    }

    handleUser=()=>{

        this.setState({

            userpopup:true

        })

        console.log(this.state.userdeleteid);
    }

    userpopupclose(ref){

        this.setState({

            adduserop: false,
            userpopup: false

        })
        console.log(ref);
        if(ref === 'refresh'){

            window.location = "/listuser";
        }


    }





    FilterList(e){

        this.setState({filter:e.target.value})

    }



    render() {

        console.log(this.state.adduserop);

        console.log(this.props.getUserList.Users) ;

        var lusers=[];
        if ( this.props.getUserList.Users !== undefined ){
            if (this.state.filter !== "" ) {
                lusers= this.props.getUserList.Users.filter(
                    item => (item.fname.toLowerCase().search(this.state.filter.toLowerCase()) >= 0 || item.emailid.toLowerCase().search(this.state.filter.toLowerCase())  >= 0 ||  item.phone.search(this.state.filter)  >= 0 )
                )

            }else{
                lusers =  this.props.getUserList.Users ;

            }

            console.log(lusers)
        }

        return (
            <div className="page-content-holder">
                <Sidebar selection="users" />
                <div className="tab-switching-panel-holder">



                    <div className="tab-switching-panel" id="users" >
                        <div className="panel-options-head">
                            <h5>Manage Users</h5>
                            {/*<div className="search-option-block">
                                <input type="text" name="search-in" placeholder="Search users by Name, Role" className="search-input-in" onChange = {this.FilterList} />
                                <span className="sprite-in icon--search in-search-box"></span>
                            </div>*/}
                            <div className="user-options-block">
                                <button onClick={this.handleUser} className="btn btn-red icon-btn icon-btn--delete">Remove</button>
                                <button className="btn btn-blue icon-btn icon-btn--add" onClick={this.handlePopup}>Add User</button>
                            </div>
                        </div>

                        <div className="panel-options-content">
                            <div className="scrollable table-wrapper">
                                <table className="user-table table-in">
                                    <thead>
                                    <tr key="user-head">
                                        <th>User Name</th>
                                        <th>User Role</th>
                                        <th>Phone Number</th>
                                        <th>Email Address</th>
                                        <th>Account Created</th>
                                    </tr>
                                    </thead>
                                    { lusers.length > 0 ? (
                                        <Displayrows  deleteid={this.deleteid}  handlcheckUser={this.handlcheckUser}  displist = {lusers} length={this.props.getUserList.Users.length} />
                                    ) : 'Loading...' }


                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                {this.state.adduserop== true?  (<Adduser  refresh={this.state.refresh}  userpopupclose={this.userpopupclose.bind(this)}/>):''}

                { (this.state.userpopup== true && this.state.userdeleteid !== "") && <Deleteuser  userdeleteid={this.state.userdeleteid} refresh={this.state.refresh}  userpopupclose={this.userpopupclose.bind(this)}/> }
            </div>



        );

    }
}



const mapStateToProps = state => ({
    getUserList:state.UserReducer.getUserList


});



export default  withRouter(connect(mapStateToProps, {
    user:userActions.listallusers,
    deleteUser:userActions.deleteuser
})(Listuser));
