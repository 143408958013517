import {loginForm} from "../services/login.services";
import {login} from '../utilities/AuthService';
//this.Auth = new AuthService();
export const UserLogin = (username, password) => dispatch => {
    let logindata = loginForm.login(username, password);
    console.log(logindata);
    logindata.then(logindata => {
        console.log(logindata)
        if (logindata.data.success) {
            console.log(logindata.data.users[0].userrole)
            login(logindata.data.users[0].token, logindata.data.users[0].userrole, logindata.data.users[0].fname, logindata.data.users[0].lname, logindata.data.users[0].profile).then(res => {
                window.location = "/listentries";
            })
        } else {

            dispatch({
                type: 'LOGIN_FAILED',
                validdataerrors:logindata.data.success

            })
        }

    })

};


