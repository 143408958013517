import React, {Component} from 'react';
import {userRole, userName, userProfile} from '../../utilities/AuthService';
import {connect} from "react-redux";
import {withRouter} from 'react-router';



class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state= {
            activemenu: this.props.selection,
            prevPage:'',
            userrole:''


        }
        this.Switchuser = this.Switchuser.bind(this)
        this.Switchregistration = this.Switchregistration.bind(this)
        this.Switchstory = this.Switchstory.bind(this)
        this.Switchshortlist = this.Switchshortlist.bind(this)

    }

    Switchuser(){

        window.location = "/listuser";
    }

    Switchregistration(){

        window.location = "/listregistration";
    }
    Switchstory(){

        window.location = "/liststories";
    }

    Switchentries(){

        window.location = '/listentries'
    }

    Switchshortlist(){

        window.location = '/listshortlisted';
    }


    componentDidMount() {

        console.log(userRole().userrole);

        this.setState({

            userrole:userRole().userrole
        })

        console.log(this.state.userrole);

    }

    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }



    render() {
        console.log(this.props)

        return (

            <div className="side-bar">
                <div className="user-area">
                    <div className="user-image-holder">
                        <img src={`./assets/images/${userProfile().profile}`} className="user-image-in" />
                    </div>
                    <div className="user-details-holder">
                        <h2 className="user-name-in">{userName().fname} {userName().lname}</h2>
                        <h6 className="user-role-in">{this.Capitalize(userRole().userrole)}</h6>
                    </div>
                </div>



                <div className="tab-switching-area">
                    <ul className="tab-list">
                        {userRole().userrole==="admin"&&
                            <React.Fragment>
                                <li   className={`tab-list__item ${this.state.activemenu  === "users" ?'active':''}`} >
                                    <div className="tab-list__item__content tab-list__item__content--users" onClick={this.Switchuser}>
                                        <label>Users</label>
                                    </div>
                                </li>
                                <li className={`tab-list__item ${this.state.activemenu  === "registrations" ?'active':''}`} >
                                    <div className="tab-list__item__content tab-list__item__content--register" onClick={this.Switchregistration}>
                                        <label>Registrations</label>
                                    </div>
                                </li>

                            </React.Fragment>
                        }

                                <li className={`tab-list__item ${this.state.activemenu  === "entries" ?'active':''}`}>
                                    <div className="tab-list__item__content tab-list__item__content--evaluation" onClick={this.Switchentries}>
                                        <label>Entries</label>
                                    </div>
                                </li>
                        {userRole().userrole==="admin"&&
                        <React.Fragment>

                                <li className={`tab-list__item ${this.state.activemenu  === "stories" ?'active':''}`} >
                                    <div className="tab-list__item__content tab-list__item__content--story" onClick={this.Switchstory}>
                                        <label>Scoreboard</label>
                                    </div>
                                </li>


                        </React.Fragment>
                        }




                    </ul>
                </div>

            </div>
        );
    }

}


const mapStateToProps = state => ({


});

export default withRouter(connect(mapStateToProps, {


})(Sidebar));


