import {NewuserAddition} from '../services/useraddition.services'

export const AddnewUsers = (userFirstname, userLastname, userrole, userEmailid, userPhone, userPassword) => dispatch => {

    let addnewusers = NewuserAddition.useraddition(userFirstname, userLastname, userrole, userEmailid, userPhone, userPassword);
    console.log(addnewusers);
    const name= userFirstname + userLastname;
    const userdata = {name,userrole,userEmailid,userPhone}
    /* if (addnewusers){

        window.location = "/listuser";


    } */

};