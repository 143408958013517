import {Registrations} from '../services/registration.services'

export const listallregistrations = (params) => dispatch => {

    let listallregistrations = Registrations.listUsers(params);
    console.log("fetch data") ;
    console.log(listallregistrations);
    listallregistrations.then(listallregistrations => {
        console.log(listallregistrations);
        dispatch({
            type: 'LIST_All_REGISTRATIONS',
            listallregistrations:listallregistrations

        })
    })

};