import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import configureStore from './configureStore';
import Login from './components/pages/Login'
import Listuser from './components/pages/Listuser'
import Listregistration from './components/pages/Listregistration'
import Liststories from './components/pages/Liststories'
import Topbar from './components/sections/Topbar'
import Readmore from './components/pages/Readmore'
import {loggedIn} from './utilities/AuthService';
import Listshortlisted from "./components/pages/Listshortlisted";
import Listentries from "./components/pages/Listentries";


const store = configureStore();

const LoginLayout = ({children, ...rest}) => {
    return (
        <div className="wrapper">


        {children}
        </div>
);
};



const MainLayout = ({children},...rest) => {
    //console.log(rest)
    return (
        <div className="wrapper">
            <Topbar />

                    {children}

        </div>
    );
};



const LoginLayoutRoute = ({component: Component, ...rest}) => {


    if (loggedIn()) {

            return <Redirect to='/listuser' />

    }

    return (
        <Route {...rest} render={matchProps => (
        <LoginLayout>
        <Component {...matchProps} />
    </LoginLayout>
)}/>
);
};


const MainLayoutRoute = ({component: Component, ...rest }) => {

    if (!loggedIn()) {

            return <Redirect to='/login'/>

    }

    return (
        <Route {...rest}  render={matchProps => (
            <MainLayout >
                <Component {...matchProps}  />
            </MainLayout>
        )}/>
    );
};



function App() {
    return (
        <Provider store={store}>
        <Router>
        <Switch>
        <LoginLayoutRoute exact path="/"  component={Login}/>
        <LoginLayoutRoute exact path="/login/signup"  component={Login}/>
        <MainLayoutRoute exact path="/listuser"  component={Listuser}/>
        <MainLayoutRoute exact path="/listregistration"  component={Listregistration}/>
        <MainLayoutRoute exact path="/liststories"  component={Liststories}/>
        <MainLayoutRoute exact path="/listshortlisted"  component={Listshortlisted}/>
        <MainLayoutRoute exact path="/listentries"  component={Listentries}/>
        <MainLayoutRoute exact path="/readmore"  component={Readmore}/>


    </Switch>
    </Router>
    </Provider>
);
}

export default App;