import React,{Component} from 'react';
import  {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {getProfile} from "../../utilities/AuthService";
import * as commonActions from "../../actions/Common";




class FinalStory extends Component{

    constructor(props) {
        super(props);
    }


    handleClosepopup=()=>{

        this.props.userpopupclose('no-refresh')
    }

    Listing() {
        console.log("logout") ;
        //this.props.logout(getProfile().cookie)
        window.location = "/listentries";
    }




    render(){

        var nextstoryid = this.props.nextstoryid;
        var page = this.props.page;

        return(

            <div className="cs-modal-in cs-modal--lg show">
                <div className="modal-content delmodal">
                    <div className="cs-modal-head">
                        <h3 className="cs-modal-header-in">Thank You!</h3>
                        <span className="modal-out" onClick={this.handleClosepopup}></span>
                    </div>
                    <div className="cs-modal-body">
                        <div className="cs-modal-body-in">

                            <div className="btn-all-holder">
                                <a onClick={()=>this.Listing()} className="btn btn-green">Back to Entries</a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        );

    }
}

const mapStateToProps = state => ({



});

export default  withRouter(connect(mapStateToProps, {

    logout: commonActions.logOut,

})(FinalStory));




