import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as commonActions from "../../actions/Common";
import {getProfile} from "../../utilities/AuthService";

class Topbar extends Component {

    constructor(props) {
        super(props);
        this.LogOut = this.LogOut.bind(this)

    }


    LogOut() {
        console.log("logout") ;
        this.props.logout(getProfile().cookie)
    }



    render() {
        console.log(this.props)

        return (
            <nav className="page-nav">
                <div className="logo-area">
                    <h1>E A Fernandez Memorial Award</h1>
                </div>
                <div className="nav-options">
                    <ul className="nav-option-list">
                        <li className="nav-options-list__items">
                            <div className="nav-option-button button--notification column-flex active-in">
                                <span className="sprite-in icon--notification"></span>
                                <span className="hover-indic-message">No new notifications</span>
                            </div>
                        </li>
                        <li className="nav-options-list__items">
                            <div className="nav-option-button button--logout column-flex" onClick={this.LogOut}>
                                <span className="sprite-in icon--logout" ></span>
                                <span className="hover-indic-message">Logout</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }

}


const mapStateToProps = state => ({


});

export default withRouter(connect(mapStateToProps, {
    logout: commonActions.logOut,

})(Topbar));


