import axios from 'axios'
import {API_PATH} from '../constants/index'

export const NewuserAddition = {
    useraddition
};


function useraddition(userFirstname, userLastname, userrole, userEmailid, userPhone, userPassword){
    return axios.post(API_PATH + 'admin/insertadminusers', {

        "firstname": userFirstname,
        "lastname": userLastname,
        "userrole": userrole,
        "emailid": userEmailid,
        "phonenumber": userPhone,
        "username": userEmailid,
        "password": userPassword

    })
}