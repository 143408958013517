import {combineReducers} from 'redux';

const listStorydata =  (state = {Stories:{},results:{}}, action) => {
    switch (action.type) {
        case "LIST_All_STORIES":
            return {
                ...state,
                Stories: action.listallstories,
            };
            break;

        default:
            return state;
    }
};


const selectedStory =  (state = {Stories:{},results:{}}, action) => {
    switch (action.type) {
       case "LIST_CURRENT_STORY":
            return {
                ...state,
                Stories: action.currentstory,
            };
            break;

        default:
            return state;
    }
};


const allAssets =  (state = {Assets:[]}, action) => {
    switch (action.type) {
        case "LIST_ALL_ASSETS":
            return {
                ...state,
                Assets: action.allassets,
            };
            break;

        default:
            return state;
    }
};


const shortlistedStory =  (state = {Shortlist:{},results:{}}, action) => {
    switch (action.type) {
       case "LIST_All_SHORTLISTS":
            return {
                ...state,
                Shortlist: action.listShortlistedstories,
            };
            break;

        default:
            return state;
    }
};

const selectedCriteria =  (state = {Criterias:{},results:{}}, action) => {
    switch (action.type) {
        case "LIST_ALL_CRITERIA":
            return {
                ...state,
                Criterias: action.allcriterias,
            };
            break;

        default:
            return state;
    }
};

const scoreUpdates =  (state = {Score:""}, action) => {
    switch (action.type) {
        case "SCORE_UPDATES":
            return {
                ...state,
                Score:action.updatescore,
            };
            break;

        default:
            return state;
    }
};

const shortlistStory =  (state = {Status:false}, action) => {
    switch (action.type) {
        case "SHORTLIST_STORY":
            return {
                ...state,
                Status:true,
            };
            break;

        default:
            return state;
    }
};

const totalScores =  (state = {Allscores:{}}, action) => {
    switch (action.type) {
        case "SCORE_CHECK":
            return {
                ...state,
                Allscores:action.totalscores,
            };
            break;

        default:
            return state;
    }
};

const ListEntries =  (state = {Entries:{}}, action) => {
    switch (action.type) {
        case "LIST_All_ENTRIES":
            return {
                ...state,
                Entries: action.listAllEntries,
            };
            break;

        default:
            return state;
    }
};

const nextStory =  (state = {Nextstory:{}}, action) => {
    switch (action.type) {
        case "LIST_NEXT_STORY":
            return {
                ...state,
                Nextstory: action.nextstory,
            };
            break;

        default:
            return state;
    }
};
const storyMarks =  (state = {assignMarks:{}}, action) => {
    switch (action.type) {
        case "ASSIGN_STORY_MARKS":
            return {
                ...state,
                assignMarks: action.assignMarks,
            };
            break;

        default:
            return state;
    }
};



export const Story = combineReducers({
    listStorydata,
    selectedStory,
    selectedCriteria,
    scoreUpdates,
    shortlistedStory,
    shortlistStory,
    totalScores,
    allAssets,
    ListEntries,
    nextStory,
    storyMarks
});

export default Story;