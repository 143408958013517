import React,{Component} from 'react';
import  {withRouter} from 'react-router'
import {connect} from 'react-redux'
import * as storyActions from "../../actions/Story";



class Playassets extends Component{

    constructor(props) {
        super(props);

    }

    componentDidMount() {

        this.props.assets(this.props.storyid);

    }

    handleClosepopup=()=>{

        this.props.assetspopupclose()

    }





    render(){

        var selectedasset = this.props.selectedasset;
        var type = this.props.type;
        var listAllAssets = [];

        console.log(selectedasset);
        console.log(this.props.listAllAssets.Assets)

        if (this.props.listAllAssets.Assets.data !== undefined ) {
            listAllAssets = this.props.listAllAssets.Assets.data ;

        }

        console.log( this.props.listAllAssets.Assets.length !==0 ?listAllAssets.videos :[]);
        let  video =  this.props.listAllAssets.Assets.length !==0 ? listAllAssets.videos :[];
        let  audio =  this.props.listAllAssets.Assets.length !==0 ?listAllAssets.audios :[];

        return(

            <div className="cs-modal-in cs-modal--lg show">


                <div className="cs-modal cs-modal-md" id="playassets">
                    <div className="modal-content close-btn">
                        <div className="cs-modal-head">
                            <h3 className="cs-modal-header-in">Uploaded Assets</h3>
                            <span className="modal-out" onClick={this.handleClosepopup}></span>
                        </div>
                        <div className="modal-body tnc-modal preview-block">
                            <div className="modal-form-row ">
                                <div className="preview-holder">
                                    <h2 className="curly modal-header">Play Video/Audio</h2>
                                    <div className="popup-contentarea">
                                        <div className="popup-playarea">
                                            <div id="loadasset">

                                                {type === "video" ?

                                                    <video width="320" height="240" controls><source src={`https://eafawardcopy.s3.ap-south-1.amazonaws.com/${selectedasset}`} type="video/mp4"/></video>
                                                    :
                                                    <audio controls><source src={`https://eafawardcopy.s3.ap-south-1.amazonaws.com/${selectedasset}`} type="audio/mpeg"/></audio>

                                                }

                                            </div>
                                        </div>
                                        <div className="popup-listarea">
                                            <div className="list-assets popup">

                                                { audio.map((audiolist,index) =>
                                                <div className="list-audio">
                                                    <ul id="filesaudiopop">
                                                        <li onClick={()=>this.props.assetChange(audiolist.audio_filename, audiolist.asset_type)}><img className="audio-img" src="../assets/images/audio.png" alt="" />{audiolist.audio_filename}</li>
                                                    </ul>
                                                </div>
                                                )}
                                                { video.map((videolist,index) =>
                                                    <div className="list-video">
                                                        <ul id="filesvideopop">
                                                            <li onClick={()=>this.props.assetChange(videolist.video_filename, videolist.asset_type)}><img className="video-img" src="../assets/images/video.jpg" alt="" />{videolist.video_filename}</li>
                                                        </ul>
                                                    </div>

                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                </div>
            </div>
            </div>


        );

    }
}



const mapStateToProps = state => ({
    listAllAssets:state.StoryReducer.allAssets

});



export default  withRouter(connect(mapStateToProps, {
    assets:storyActions.readAssets,
})(Playassets));
