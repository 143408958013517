import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'
import * as storyActions from "../../actions/Story";



function ToTitleCase(props)
{
    let str = props.name  ;
    return str.replace(/\b\w/g, function (txt) { return txt.toUpperCase(); });
}



class Listshortlisted extends Component {

    constructor(props) {
        super(props);
        this.ReadMore=this.ReadMore.bind(this)
        this.Selection  = this.Selection.bind(this)
        this.state={
            filter: '',
        }

    }

    FilterList(e){
        this.setState({filter:e.target.value})
    }



    componentDidMount() {

        this.props.shortlistedstories();

    }


    ReadMore(e,id){
        console.log(id) ;
        window.location = "/readmore?id="+id+'&page=shortlist';

    }

    Selection(e){
        console.log(e.target.value) ;

    }







    render() {
        console.log(this.props.listShortlisteddata.Shortlist)
        var lstories =  []  ;
        console.log(this.props.listShortlisteddata.Shortlist.data) ;
        if (this.props.listShortlisteddata.Shortlist.data !== undefined ){
            console.log("inside loop");
            console.log(this.props.listShortlisteddata.Shortlist.data) ;
            if (this.state.filter !== "" ) {
                lstories = this.props.listShortlisteddata.Shortlist.data.filter(
                    item => (item.fname.toLowerCase().search(this.state.filter.toLowerCase()) >= 0 )
                )

            }else{
                lstories =  this.props.listShortlisteddata.Shortlist.data  ;

            }
        }


        return (
            <div className="page-content-holder">
                <Sidebar selection="shortlist" />
                <div className="tab-switching-panel-holder">
                    <div className="tab-switching-panel" id="users" >
                        <div className="panel-options-head">
                            <h5>List Stories</h5>
                            <div className="search-option-block">
                                <input type="text" name="search-in" placeholder="Search users by Name, Role" className="search-input-in" onChange = {this.FilterList} />
                                <span className="sprite-in icon--search in-search-box"></span>
                            </div>
                            <div className="user-options-block">

                            </div>
                        </div>

                        <div className="panel-options-content">
                            <div className="scrollable table-wrapper">
                                <table className="user-table table-in">
                                    <thead>
                                    <tr>

                                        <th>Candidate Name</th>
                                        <th>Status</th>
                                        <th>Judge1</th>
                                        <th>Judge2</th>
                                        <th>Judge3</th>
                                        <th>Judge4</th>
                                        <th>Judge5</th>
                                        <th>Avg Score</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { lstories.map((list,index) =>
                                        <tr  key={`list-shortlisted-table-data-`+index} onClick={(e) => this.ReadMore(e,list.storyid)}>

                                            <td>
                                                <label className="cs-checkbox">
                                                    <input type="radio" name="selctedstory" className="cs-checkbox-in" value={list.storyid} onClick={this.Selection} />
                                                    <span className="cs-placeholder"></span>
                                                </label>
                                                <label className="sl-no">{index+1}</label>
                                                <label className="inline-text">{list.fname} {list.lname} </label>
                                            </td>
                                            <td>{list.eval_status.replace("_"," ").toUpperCase()}</td>
                                            <td>{list.judge1}</td>
                                            <td>{list.judge2}</td>
                                            <td>{list.judge3}</td>
                                            <td>{list.judge4}</td>
                                            <td>{list.judge5}</td>
                                            <td>{list.avg_score}</td>

                                        </tr>
                                    )}
                                    </tbody>





                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );

    }
}


const mapStateToProps = state => ({
    listShortlisteddata:state.StoryReducer.shortlistedStory,


});



export default  withRouter(connect(mapStateToProps, {
    shortlistedstories:storyActions.listShortlistedstories
})(Listshortlisted));