import axios from 'axios'
import {API_PATH} from '../constants/index'


export const Users = {
    listUsers,
    deleteUsers


};


function listUsers(){
    return axios.get(API_PATH + 'admin/userlist')
}

function deleteUsers(params){

    console.log(params);
    return axios.get(API_PATH + 'admin/removeuser?userid='+params)
}
