import {combineReducers} from 'redux';
import UserReducer from './UserReducer'
import RegistrationReducer from './RegistrationReducer'
import LoginfailReducer from './LoginfailReducer'
import StoryReducer from './StoryReducer'

const app = combineReducers({
    UserReducer,
    RegistrationReducer,
    LoginfailReducer,
    StoryReducer,

});
export default app;