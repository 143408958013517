import axios from 'axios'
import {API_PATH} from '../constants/index'

export const loginForm = {
    login,
    sessionValidate
};


function login(username,passs){
    return axios.post(API_PATH + 'admin/adminlogin', {

        "username": username,
        "password": passs

    })
}
function sessionValidate(sid,uid){
    return axios.post(API_PATH + 'login/checksession', {

        "sid": sid,
        "uid": uid,

    })
}