import React, {Component} from 'react';
import {getProfile, tokenId, userName, userRole} from '../../utilities/AuthService';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Sidebar from '../sections/Sidebar'
import * as storyActions from "../../actions/Story";
import Parser from 'html-react-parser';
import Playassets from "./Playasstes";
import ReactToPrint from 'react-to-print';
import NextStory from "./NextStoryPopup";
import FinalStory from "./FinalStoryPopup";
import * as commonActions from "../../actions/Common";
import {assignStoryMarks} from "../../actions/Story";



function ToTitleCase(props)
{
    let str = props.name  ;
    return str.replace(/\b\w/g, function (txt) { return txt.toUpperCase(); });
}

class ComponentToPrint extends React.Component {
    render() {
        return (
            <div class="print-body">
                {this.props.story}
            </div>
        );
    }
}


class Readmore extends Component {

    constructor(props) {
        super(props);
        this.state={
            storyid : '',
            notes:[],
            remarks:'',
            criteriaerror:false,
            totalscore:'',
            encid:'',
            page:'',
            active:'',
            playassets:false,
            type:'',
            action:'insert',
            userrole:'',
            selectedOption:'',
            nextstorypop:false,
            nextid:0,
            finalstorypopup:false,
            submitbutton:true,
            mark:''

        }

    }




    handleAssets=(asset, type)=>{


        this.setState({


            selectedasset:asset,
            type: type,
            playassets: true

        })


    }

    assetspopupclose=(e)=>{

        this.setState({

            playassets: false
        })
    }

    handleOptionChange=(e)=>{

        this.setState({
            selectedOption: e.target.value
        });

    }
    handleInputChange=(e)=>{
        this.setState({
            mark: e.target.value
        });
    }
    saveMarks=(e)=>{
        this.props.assignStoryMarks(this.state.storyid,this.state.mark)
    }
    handleScoresubmitexit=(e)=>{

        let criteriavalid=0;
        e.preventDefault();


        if(this.state.selectedOption==""){
            criteriavalid=1;
            this.setState({
                criteriaerror:true
            })

        }

        else{
            criteriavalid=0;
            this.setState({
                criteriaerror:false
            })
        }

        console.log(this.state.action);

        if(criteriavalid==0){

            console.log(this.state.action);


                this.props.scores(this.state.storyid,this.state.encid, this.state.selectedOption,this.state.action).then(()=> this.props.logout(getProfile().cookie));



        }


    }


    handleScoresubmit=(e)=>{

        let criteriavalid=0;
        e.preventDefault();


        if(this.state.selectedOption==""){
            criteriavalid=1;
           this.setState({
               criteriaerror:true
           })

        }

        else{
            criteriavalid=0;
            this.setState({
                criteriaerror:false
            })
        }

        console.log(this.state.action);

        if(criteriavalid==0){

            console.log(this.state.action);

            var nextstory = this.props.nextstorydetails.Nextstory.data;

            console.log(nextstory);
            console.log(nextstory.length);

            if ( nextstory.length !==0) {
               var nextid = nextstory[0].storyid;

            }

            var page = this.state.page;

            console.log(this.state.action);

            if (nextstory.length ===0){
                this.props.scores(this.state.storyid,this.state.encid, this.state.selectedOption,this.state.action).then(()=>this.setState({nextstorypop:false, finalstorypopup:true, submitbutton:false}));
            }
            else{

                // this.props.scores(this.state.storyid,this.state.encid, this.state.selectedOption,this.state.action).then(()=> window.location = "/readmore?id="+nextid+"&page="+page);

                this.props.scores(this.state.storyid,this.state.encid, this.state.selectedOption,this.state.action).then(()=> this.setState({nextstorypop:true, finalstorypopup:false, submitbutton:false}));
            }


        }


        document.getElementById("create-course-form").reset();


    }



    componentWillMount() {
        let params = (new URL(document.location)).searchParams;
        let storyid = params.get("id") ? params.get("id") : 0;
        let page = params.get("page") ? params.get("page") :'';
        if(storyid == 0){
            window.location = "/liststories" ;

        }else{

            this.setState({storyid:storyid, page:page })

        }

        this.setState({

            encid:tokenId().id
        })

        this.setState({

            userrole: userRole().userrole
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot){
        var action = 'insert';
        var flag1 = false;
        var flag2 = false;
        var remarks = '';
        var nextid = 0;
        var submitbutton = true;

        if(prevProps.currentstory.Stories !== this.props.currentstory.Stories ) {
            if (this.props.currentstory.Stories.data !== undefined) {

                flag1 = true;

                var currentstory = this.props.currentstory.Stories.data;

                remarks   = currentstory[0].remarks ;

                if (currentstory[0].evaluator == null){

                    action = 'insert'

                }
                else {

                    action = 'update'
                }

                if (remarks !== null){

                    submitbutton = false
                }
            }
        }


        if(prevProps.nextstorydetails.Nextstory !== this.props.nextstorydetails.Nextstory ) {
            if (this.props.nextstorydetails.Nextstory.data !== undefined) {

                flag2 = true;
                var nextstory = this.props.nextstorydetails.Nextstory.data;

                console.log(nextstory);

                if ( nextstory.length !==0) {
                    nextid = nextstory[0].storyid;


                }

            }
        }

        if(prevProps.nextstorydetails.Nextstory !== this.props.nextstorydetails.Nextstory ) {
            if (this.props.nextstorydetails.Nextstory.data !== undefined) {

                flag2 = true;
                var nextstory = this.props.nextstorydetails.Nextstory.data;

                console.log(nextstory);

                if ( nextstory.length !==0) {
                    nextid = nextstory[0].storyid;
                    this.setState({marks :nextstory[0].mark})



                }

            }
        }
        if(prevProps.storyMarks.assignMarks !== this.props.storyMarks.assignMarks ) {
            if (this.props.storyMarks.assignMarks !== undefined) {

                var storyMarks = this.props.storyMarks.assignMarks;
                this.props.stories(this.state.storyid, this.state.encid);
                this.props.listallentries(this.state.encid);
                // if ( storyMarks.length !==0) {
                //     //this.setState({mark:storyMarks[0].mark})
                //
                //
                // }
            }
        }

        if (flag1==true && flag2==true){

            this.setState({selectedOption: remarks, action: action, nextid: nextid, submitbutton:submitbutton});

        }
        else if (flag1==true){

            this.setState({selectedOption: remarks, action: action, submitbutton:submitbutton});

        }

        else if (flag2==true){

            this.setState({nextid: nextid, submitbutton:submitbutton});

        }


    }



    componentDidMount() {

      console.log("fetch single story");
      console.log(this.state.storyid);
      console.log(this.state.encid);
      this.props.stories(this.state.storyid, this.state.encid);
      this.props.nextstory(this.state.storyid, this.state.encid);
      this.props.checkScore(this.state.storyid,this.state.encid);
      this.props.allscores(this.state.storyid, this.state.encid);


    }


    userpopupclose(ref){

        this.setState({

            nextstorypop: false,
            finalstorypopup:false

        })
        console.log(ref);
        if(ref === 'refresh'){

            window.location = "/listentries";
        }


    }






    render() {


        console.log(this.state.nextid);

        console.log(this.state.selectedOption);

        console.log(this.props.nextstorydetails.Nextstory);


        console.log(this.props.overallscore.Allscores);

        console.log(this.props.nextstorydetails.Nextstory);


        var currentstory = [];
        var allscores = [];


        if (this.props.currentstory.Stories.data !== undefined ) {
            currentstory = this.props.currentstory.Stories.data ;

        }



        if (this.props.overallscore.Allscores.data !== undefined ) {
            allscores = this.props.overallscore.Allscores.data ;

        }
        console.log(currentstory);
        var count = allscores.length;

        console.log(count);

        return (
            <div className="page-content-holder">
                <Sidebar selection={this.state.page} />
                <div class="tab-switching-panel-holder">
                <div className="tab-switching-panel" id="stories-detail">

                { currentstory.map((list,index) =>

             <div className="two-side-block innerpages">
                    <div className="two-side-block__left story-detail">


                        <div className={`panel-options-content ${list.video_filename ===null && list.audio_filename ===null ? 'no-media' :''}`}>
                            <div className="story-about-panel">
                            <p className="inline-text">
                            <span className="qs-in">Author :</span>
                            <span className="answer-in"> {list.fname} {list.lname}</span>
                            </p>

                            <p className="inline-text">
                            <span className="qs-in">Word Count :</span>
                            <span className="answer-in"> {list.word_count}</span>
                            </p>
                            </div>
                            <div className="story-panel">

                               {/* {list.story!==null? (Parser(list.story)):(list.story)}*/}

                                <ComponentToPrint story={Parser(list.story)} ref={el => (this.componentRef = el)} />

                            </div>

                            {this.state.userrole ==='admin' ? (

                            <div className="user-options-block">
                                { /* <button className="btn print-btn icon-btn icon-btn--print">Print story</button> */ }

                                <ReactToPrint
                                    trigger={() => <a className="btn print-btn icon-btn icon-btn--print" href="#">Print story</a>}
                                    content={() => this.componentRef}
                                />

                            </div>

                            ):''}
                        </div>

                        {list.video_filename!==null || list.audio_filename!==null ? (

                        <div className="attachments-in-block">
                            <h5>Attached Media Files</h5>

                            {list.video_filename!==null? (


                                <div className="video-holder">
                                    <div className="video-in-each">
                                        <div className="video-icon-in"></div>
                                        <div className="video-description-area">
                                            <h6  onClick={() => this.handleAssets(list.video_filename, "video")}>{list.video_filename}</h6>

                                        </div>
                                    </div>

                                </div>

                            ) :''}


                            <div className="audio-holder">
                                {list.audio_filename!==null? (
                                    <div className="audio-in-each">

                                        <div className="audio-icon-in"></div>

                                        <div className="audio-description-area">
                                            <h6  onClick={() => this.handleAssets(list.audio_filename, "audio")}>{list.audio_filename}</h6>
                                        </div>
                                    </div> ) :''}
                            </div>
                        </div>

                        ) :''}
                    </div>



                    <div className="two-side-block__right accordion-in">


                        <div className="two-side-block__right__head">
                        <h5>{userName().fname} {userName().lname}</h5>
                        </div>

                                <div className="two-side-block__right__body">
                                    <div className="form-holder">
                                        <form className="score-form form-in" id="create-course-form">

                                            <div className="radio">
                                                <label class="cs-radio-in">
                                                    <input type="radio" value="Below Average"
                                                           checked={this.state.selectedOption === 'Below Average'}
                                                           onChange={this.handleOptionChange} />
                                                    <span className="cs-radio-placeholder"></span>
                                                    Below Average
                                                    <span className="inline-definition">Should be doing something else</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label class="cs-radio-in">
                                                    <input type="radio" value="Average"
                                                           checked={this.state.selectedOption === 'Average'}
                                                           onChange={this.handleOptionChange} />
                                                    <span className="cs-radio-placeholder"></span>
                                                    Average
                                                    <span className="inline-definition">Typical and expected</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label class="cs-radio-in">
                                                    <input type="radio" value="Good"
                                                           checked={this.state.selectedOption === 'Good'}
                                                           onChange={this.handleOptionChange} />
                                                    <span className="cs-radio-placeholder"></span>
                                                    Good
                                                    <span className="inline-definition">There could be hope</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label class="cs-radio-in">
                                                    <input type="radio" value="Very Good"
                                                           checked={this.state.selectedOption === 'Very Good'}
                                                           onChange={this.handleOptionChange} />
                                                    <span className="cs-radio-placeholder"></span>
                                                    Very Good
                                                    <span className="inline-definition">There is definitely hope</span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label class="cs-radio-in">
                                                    <input type="radio" value="Excellent"
                                                           checked={this.state.selectedOption === 'Excellent'}
                                                           onChange={this.handleOptionChange} />
                                                    <span className="cs-radio-placeholder"></span>
                                                    Excellent
                                                    <span className="inline-definition">You might think of hiring</span>
                                                </label>
                                            </div>

                                            <div className="radio">
                                                <label class="cs-radio-in">
                                                    <input type="radio" value="Amazing"
                                                           checked={this.state.selectedOption === 'Amazing'}
                                                           onChange={this.handleOptionChange} />
                                                    <span className="cs-radio-placeholder"></span>
                                                    Amazing
                                                    <span className="inline-definition">You'll definitely hire</span>
                                                </label>
                                            </div>{console.log(this.state.submitbutton)}
                                            {this.state.submitbutton === true  &&

                                            <div
                                                className={`form-action-area column-flex`}>

                                                <button className="submit-in btn-cs-blue eq-padding"
                                                        onClick={this.handleScoresubmit}>SUBMIT & CONTINUE EVALUATION
                                                </button>
                                                <button className="submit-in btn-cs-blue eq-padding"
                                                        onClick={this.handleScoresubmitexit}>SUBMIT & CONTINUE LATER
                                                </button>

                                            </div>

                                            }

                                        </form>
                                    </div>

                                    <div className="assigned-mark-block">
                                      <div className={"assigned-mark-header"}>
                                          <h5>Assigned Marks</h5>
                                      </div>
                                        <div className={"assigned-mark-body"}>
                                            <div className={"input-block"}>
                                                <input type={'number'} name="mark" onChange={this.handleInputChange} defaultValue={list.marks} placeholder={'Marks'}/>
                                            </div>
                                            <div className={'assigned-button'}>
                                                <button className={"general-btn"} onClick={this.saveMarks}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>



                  </div>





                )}
                </div>
                 </div>

                {this.state.playassets== true?  (<Playassets assetChange={this.handleAssets} selectedasset={this.state.selectedasset} type={this.state.type} storyid={this.state.storyid} assetspopupclose={this.assetspopupclose.bind(this)}/>):''}

                { (this.state.nextstorypop== true && this.state.nextid !== 0) && <NextStory  nextstoryid={this.state.nextid} page={this.state.page} refresh={this.state.refresh} userpopupclose={this.userpopupclose.bind(this)}/> }
                { this.state.finalstorypopup== true ? (<FinalStory userpopupclose={this.userpopupclose.bind(this)}/>):''}
            </div>

        );

    }
}


const mapStateToProps = state => ({
    currentstory:state.StoryReducer.selectedStory,
    nextstorydetails:state.StoryReducer.nextStory,
    scoreresult:state.StoryReducer.scoreUpdates,
    overallscore:state.StoryReducer.totalScores,
    storyMarks:state.StoryReducer.storyMarks,


});



export default  withRouter(connect(mapStateToProps, {
    stories:storyActions.readStory,
    nextstory:storyActions.nextstory,
    scores:storyActions.UpdateSCore,
    checkScore:storyActions.scoreCheck,
    logout: commonActions.logOut,
    allscores:storyActions.allscoresCheck,
    assignStoryMarks:storyActions.assignStoryMarks,
    listallentries:storyActions.listAllEntries
})(Readmore));
