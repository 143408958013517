import {Users} from '../services/user.services'

export const listallusers = (params) => dispatch => {

    let listallusers = Users.listUsers(params);
    listallusers.then(listallusers => {
        console.log(listallusers);
        dispatch({
            type: 'LIST_All_USERS',
            listallusers:listallusers

        })
    })

};

export const deleteuser = (params) => dispatch => {

    console.log(params);

    let deleteuser = Users.deleteUsers(params);

    if (deleteuser) {

        dispatch({
            type: 'DELETE_USERS',
            deleteuser:params

        })
    }










};

