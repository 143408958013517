import React,{Component} from 'react';
import  {withRouter} from 'react-router'
import {connect} from 'react-redux'




class NextStory extends Component{

    constructor(props) {
        super(props);
    }


    handleClosepopup=()=>{

        this.props.userpopupclose('no-refresh')
    }

    handleNextStory=(nextstoryid, page)=>{

        window.location = "/readmore?id="+nextstoryid+"&page="+page;


    }


    render(){

        var nextstoryid = this.props.nextstoryid;
        var page = this.props.page;

        return(

            <div className="cs-modal-in cs-modal--lg show">
                <div className="modal-content delmodal">
                    <div className="cs-modal-head">
                        <h3 className="cs-modal-header-in">Would you like to evaluate next story?</h3>
                        <span className="modal-out" onClick={this.handleClosepopup}></span>
                    </div>
                    <div className="cs-modal-body">
                        <div className="cs-modal-body-in">

                            <div className="btn-all-holder">
                                <a onClick={()=>this.handleNextStory(nextstoryid, page)} className="btn btn-green">Yes</a>
                                <a  onClick={this.handleClosepopup} className="btn btn-red">No</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        );

    }
}

const mapStateToProps = state => ({



});

export default  withRouter(connect(mapStateToProps, {
})(NextStory));




