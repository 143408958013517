import {combineReducers} from 'redux';

const listRegistrationdata =  (state = {Registrations:{},results:{}}, action) => {
    switch (action.type) {
        case "LIST_All_REGISTRATIONS":
            return {
                ...state,
                Registrations: action.listallregistrations,
            };
            break;
        default:
            return state;
    }
};



export const Registration = combineReducers({
    listRegistrationdata
});

export default Registration;