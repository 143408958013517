import {combineReducers} from 'redux';

const getUserList =  (state = {Users:{},DeleteUsers:{},results:{}}, action) => {
    switch (action.type) {
        case "LIST_All_USERS":
            return {
                ...state,
                Users: action.listallusers.data,
            };
            break;

        case "DELETE_USERS":
            return {
                ...state,
                DeleteUsers: state.Users.filter(d=>d.id!=action.deleteuser),
            };
            break;
        default:
            return state;
    }
};



export const User = combineReducers({
    getUserList
});

export default User;